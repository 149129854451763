<template>
    <painel :titulo="`Cliente - ${cliente?.codParceiro} - ${cliente?.nomeParceiro}`" icone="pi pi-comments" :refreshFunction="obterCliente">
        <div class="mb-4">
            <TabView v-model:activeIndex="activeIndex">
                <TabPanel header="Dados Gerais">
                    <dados-gerais :cliente="cliente"></dados-gerais>
                </TabPanel>
                <TabPanel header="Dados Recebidos">
                    <vue-json-pretty v-if="cliente" :data="JSON.parse(cliente?.dadosRecebidos)" :showDoubleQuotes="true"></vue-json-pretty>
                </TabPanel>
                <TabPanel header="Objeto Integração" v-if="cliente && cliente.integracaoObjeto">
                    <vue-json-pretty :data="JSON.parse(cliente?.integracaoObjeto)" :showDoubleQuotes="true"></vue-json-pretty>
                </TabPanel>
                <TabPanel header="Retorno Integração" v-if="cliente && cliente.integracaoRetorno">
                    <span v-if="!cliente?.integracaoRetorno.isJson()"
                        ><strong>{{ cliente?.integracaoRetorno }}</strong></span
                    >
                    <vue-json-pretty
                        v-if="cliente?.integracaoRetorno.isJson()"
                        :data="JSON.parse(cliente?.integracaoRetorno)"
                        :showDoubleQuotes="true"
                    ></vue-json-pretty>
                </TabPanel>
            </TabView>
        </div>
        <btn-voltar :route="{ name: 'Minerador_Clientes' }"></btn-voltar>
    </painel>
</template>

<script>
import ClientesService from './services';
import DadosGerais from './DadosGerais';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        DadosGerais,
        VueJsonPretty,
    },

    data() {
        return {
            cliente: null,
            activeIndex: 0,
        };
    },

    methods: {
        obterCliente() {
            this.$store.dispatch('addRequest');
            ClientesService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.cliente = response.data;
                } else {
                    this.cliente = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterCliente();
        if (this.$route.query.view == 'dados') {
            this.activeIndex = 1;
        }
    },
};
</script>
