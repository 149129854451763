<template>
    <detalhe titulo="Status" :size="size">
        <status :status="cliente?.statusDescr"></status>
    </detalhe>
    <detalhe titulo="Recebido em" :size="size">
        {{ $dateFormat(cliente?.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Registro de" :size="size">
        {{ $dateFormat(cliente?.dataRegistro, 'DD/MM/YYYY') }}
    </detalhe>
    <detalhe titulo="Integrado em" :size="size" v-if="cliente?.dataIntegracao">
        {{ $dateFormat(cliente?.dataIntegracao, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="CodParceiro" :size="size">
        {{ cliente?.codParceiro }}
    </detalhe>
    <detalhe titulo="Nome" :size="size">
        {{ cliente?.nomeParceiro }}
    </detalhe>
    <detalhe titulo="Atualizado em" :size="size">
        {{ $dateFormat(cliente?.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
</template>

<script>
export default {
    props: {
        cliente: {
            type: Object,
        },
    },

    data() {
        return {
            size: '180',
        };
    },
};
</script>
