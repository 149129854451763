import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_MINERADOR_PORT}${process.env.VUE_APP_API_PATH}/minerador`;
const urlBase = `${api}/clientes`;

export default {
    obterTodos(filtro) {
        return axiosJwt.get(
            `${urlBase}?dataRegistroDe=${filtro.dataRegistroDe}&dataRegistroAte=${filtro.dataRegistroAte}`
        );
    },

    obterPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },
};
